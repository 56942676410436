.App {
  text-align: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  .main-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 28px;
    width: 510px;
    .report-title {
      text-align: left;
      margin: 0 14px;
      font-family: "Noto Sans KR", sans-serif;
      font-weight: 700;
    }
    @media (max-width: 301px) {
      width: 100%;
    }
    @media (min-width: 301px) and (max-width: 500px) {
      width: 100%;
    }
    .contents-container {
      width: 100%;
      div {
        text-align: left;
      }
    }
  }
}
